import {
  Heading4,
  Icon,
  ImageLite,
  PageLink,
  Paragraph,
} from "shared/components";

import styles from "./Banner.module.scss";

export const Banner = ({ banner }) => {
  return (
    <PageLink slug={banner?.page?.slug} className={styles.banner}>
      <ImageLite image={banner?.preview?.[0]} className={styles.image} />
      <div className={styles.content}>
        <div className={styles.title}>
          <Heading4 color="light">{banner?.title}</Heading4>
          <span className={styles.arrow}>
            <Icon className={styles.icon} name="arrow_right" />
          </span>
        </div>
        <Paragraph className={styles.caption} color="light">
          {banner?.caption}
        </Paragraph>
      </div>
    </PageLink>
  );
};
